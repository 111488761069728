import React from 'react';
import { Link } from 'react-router-dom';
import { IframeComponent } from './IframeComponent';

export const ContactComponent = () => {
  return (
    <>
    <div className="contact_section layout_padding">
         <div className="container">
            <h1 className="contact_text">Contact Us</h1>
         </div>
      </div>
      <div className="contact_section_2 layout_padding">
         <div className="container-fluid">
            <div className="row">
               <div className="col-md-6 padding_0">
                  <div className="mail_section">
                     <div className="email_text">
                        <div className="form-group">
                           <input type="text" className="email-bt" placeholder="Name" name="Email"/>
                        </div>
                        <div className="form-group">
                           <input type="text" className="email-bt" placeholder="Email" name="Email"/>
                        </div>
                        <div className="form-group">
                           <input type="text" className="email-bt" placeholder="Phone Numbar" name="Email"/>
                        </div>
                        <div className="form-group">
                           <textarea className="massage-bt" placeholder="Massage" rows="5" id="comment" name="Massage"></textarea>
                        </div>
                        <div className="send_btn">
                           <div type="text" className="main_bt"><Link to="#">SEND</Link></div>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="col-md-6 padding_0">
                  <div className="map-responsive">
                    <IframeComponent />
                </div>
               </div>
            </div>
         </div>
      </div>
    </>
  )
}
