import Home from './pages/Home';
import About from './pages/About';
import Gallery from './pages/Gallery';
import Services from './pages/Services';
import Contact from './pages/Contact';
import {Routes,Route} from 'react-router-dom';
import Header from './components/Header';
import { Footer } from './components/Footer';

function App() {
    return ( 
    <div>
        <Header/>
        <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path="/about" element={<About/>}/>
            <Route path="/gallery" element={<Gallery/>}/>
            <Route path="/services" element={<Services/>}/>
            <Route path="/contact" element={<Contact/>}/>
        </Routes>
        <Footer />
    </div>
    );
}

export default App;