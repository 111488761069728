import React from 'react';
import Iframe from 'react-iframe';

export const IframeComponent = () => {
  return (
    <div>
         <Iframe src="https://www.google.com/maps/embed/v1/place?key=AIzaSyA0s1a7phLN0iaD6-UE7m4qP-z21pH0eSc&q=Eiffel+Tower+Paris+France" width="600" height="508" frameborder="0" allowfullscreen></Iframe>
                  
    </div>
  )
}
