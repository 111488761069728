import { Link } from 'react-router-dom';
import AboutComponent from '../components/AboutComponent';
import { GalleryComponent } from '../components/GalleryComponent';
import { ServicesComponent } from '../components/ServicesComponent';
import { ContactComponent } from '../components/ContactComponent';

function Home(){
    return(
        <div>
      
      <div className="banner_section layout_padding">
         <div className="container">
            <div id="main_slider" className="carousel slide" data-ride="carousel">
               <div className="carousel-inner">
                  <div className="carousel-item active">
                     <div className="row">
                        <div className="col-sm-12">
                           <div className="banner_taital">
                              <h1 className="outstanding_text">Outstanding</h1>
                              <h1 className="coffee_text">Coffee Shop</h1>
                              <p className="there_text">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, </p>
                              <div className="learnmore_bt"><a href="#">Learn More</a></div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="carousel-item">
                     <div className="row">
                        <div className="col-sm-12">
                           <div className="banner_taital">
                              <h1 className="outstanding_text">Outstanding </h1>
                              <h1 className="coffee_text">Coffee Shop</h1>
                              <p className="there_text">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, </p>
                              <div className="learnmore_bt"><a href="#">Learn More</a></div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="carousel-item">
                     <div className="row">
                        <div className="col-sm-12">
                           <div className="banner_taital">
                              <h1 className="outstanding_text">Outstanding </h1>
                              <h1 className="coffee_text">Coffee Shop</h1>
                              <p className="there_text">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, </p>
                              <div className="learnmore_bt"><a href="#">Learn More</a></div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <a className="carousel-control-prev" href="#main_slider" role="button" data-slide="prev">
               <i className="fa fa-angle-left"></i>
               </a>
               <a className="carousel-control-next" href="#main_slider" role="button" data-slide="next">
               <i className="fa fa-angle-right"></i>
               </a>
            </div>
         </div>
      </div>
      {/* <div className="about_section layout_padding">
         <div className="container">
            <div className="row">
               <div className="col-md-6">
                  <div className="about_taital_main">
                     <div className="about_taital">About Us</div>
                     <p className="about_text">Full cleaning and housekeeping services for companies and households.</p>
                     <p className="about_text">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.Lorem Ipsum is simply</p>
                     <div className="read_bt"><a href="#">Read More</a></div>
                  </div>
               </div>
               <div className="col-md-6">
                  <div className="about_img"><img src="assets/images/about-img.png" /></div>
               </div>
            </div>
         </div>
      </div> */}
      <AboutComponent />
      <GalleryComponent />
      <ServicesComponent />
      <div className="client_section layout_padding">
         <div className="container">
            <div className="row">
               <div className="col-sm-12">
                  <h1 className="client_taital">Testimonial</h1>
                  <p className="client_text">Eeven slightly believable. If you are going to use a passage of Lorem Ipsum, you need to</p>
               </div>
            </div>
         </div>
      <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
        <ol className="carousel-indicators">
          <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
        </ol>
        <div className="carousel-inner">
          <div className="carousel-item active">
            <div className="client_section_2">
                <div className="container">
                   <div className="row">
                      <div className="col-md-12">
                         <div className="testimonial_section_2">
                            <h4 className="client_name_text">Monila <span className="quick_icon"><img src="assets/images/quick-icon.png" /></span></h4>
                            <p className="customer_text">many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All themany variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some embarrassing hidden in the middle of text. All the</p>
                         </div>
                      </div>
                   </div>
                </div>
            </div>
          </div>
          <div className="carousel-item">
            <div className="client_section_2">
                <div className="container">
                   <div className="row">
                      <div className="col-md-12">
                         <div className="testimonial_section_2">
                            <h4 className="client_name_text">Monila <span className="quick_icon"><img src="assets/images/quick-icon.png" /></span></h4>
                            <p className="customer_text">many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All themany variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some embarrassing hidden in the middle of text. All the</p>
                         </div>
                      </div>
                   </div>
                </div>
            </div>
          </div>
          <div className="carousel-item">
            <div className="client_section_2">
                <div className="container">
                   <div className="row">
                      <div className="col-md-12">
                         <div className="testimonial_section_2">
                            <h4 className="client_name_text">Monila <span className="quick_icon">
                                <img src="assets/images/quick-icon.png" alt="img"/>
                                </span>
                                </h4>
                            <p className="customer_text">many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All themany variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some embarrassing hidden in the middle of text. All the</p>
                         </div>
                      </div>
                   </div>
                </div>
            </div>
          </div>
        </div>
      </div>
     </div>
      <ContactComponent />
      </div>
    );
}
export default Home;